<template>
    <div>
        <el-main>
            <el-button type="primary" style="margin-bottom: 10px" size="small" @click="add">+添加小区</el-button>
            <el-button size="small" @click="showImport = !0">导入小区</el-button>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="物业名称：">
                    <el-input size="small" v-model="searchForm.property_name" placeholder="请输入物业名称"></el-input>
                </el-form-item>
                <el-form-item label="小区名称：">
                    <el-input size="small" v-model="searchForm.nickname" placeholder="请输入小区名称"></el-input>
                </el-form-item>
                <el-form-item label="小区区域：">
                    <el-cascader v-model="searchForm.area_id" :props="{ value: 'id', label: 'name', children: '_child' }"
                        placeholder="请选择或搜索" clearable filterable :options="area" size="small"></el-cascader>
                </el-form-item>
                <el-form-item label="添加时间：">
                    <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="property_name" label="物业名称" width="200" align="center"></el-table-column>
                <el-table-column prop="community_name" label="小区名称" width="200" align="center"></el-table-column>
                <el-table-column prop="area" label="小区区域" width="300" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.province_name }}{{ row.city_name }}{{ row.area_name }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="详细地址" align="center"></el-table-column>
                <el-table-column prop="create_time" label="申请时间" width="200" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
                </el-table-column>
                <el-table-column label="操作" width="200" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="remove(scope.row.id, scope.$index)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
        <el-dialog :title="type ? '编辑小区' : '添加小区'" :visible.sync="showAdd" width="30%" :before-close="handleClose">
            <el-form ref="form" class="add" :model="addForm" :rules="rules" label-width="100px">
                <el-form-item label="选择物业：" required="true">
                    <el-select v-model="addForm.property_id" placeholder="请选择或搜索" clearable filterable style="width: 100%">
                        <el-option v-for="item in propertyList" :key="item.id" :label="item.property_name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="小区名称：" required="true">
                    <el-input v-model="addForm.community_name"></el-input>
                </el-form-item>
                <el-form-item label="小区区域：" required="true">
                    <div class="selectBox">
                        <el-select v-model="province_name" placeholder="请选择" @change="val => sonArea(0, val)">
                            <el-option v-for="(item, index) in area" :key="index" :label="item.name"
                                :value="item.name"></el-option>
                        </el-select>
                        <el-select v-model="city_name" placeholder="请选择" @change="val => sonArea(1, val)">
                            <el-option v-for="(item, index) in cityArr" :key="index" :label="item.name"
                                :value="item.name"></el-option>
                        </el-select>
                        <el-select v-model="area_name" placeholder="请选择" @change="val => sonArea(2, val)">
                            <el-option v-for="(item, index) in areaArr" :key="index" :label="item.name"
                                :value="item.name"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="经纬度：" required="true">
                    <div style="display: flex">
                        <el-input disabled v-model="addForm.longitude" placeholder="经度"></el-input>
                        <el-input disabled v-model="addForm.latitude" placeholder="纬度"></el-input>
                        <el-button type="primary" size="medium" @click="position">定位</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="详细地址：" required="true">
                    <el-input v-model="addForm.address" disabled placeholder="详细位置"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAdd = !1">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 地图弹框 -->
        <el-dialog title="提示" :visible.sync="mapFlag" width="50%">
            <div>
                <MapData ref="map" :search="searchAddress" @getValue="getValue" :lng="addForm.longitude"
                    :lat="addForm.latitude"></MapData>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="getAddress">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="导入小区" :visible.sync="showImport" width="550px">
            <el-link type="primary" :href="templatePath">下载导入模板</el-link>
            <el-input class="upload" v-model="filePath">
                <el-button slot="append">
                    上传文件
                    <input type="file" ref="inputer" @change="getFile" />
                </el-button>
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showImport = !1">取 消</el-button>
                <el-button type="primary" @click="confirmImport">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import Paging from '@/components/paging';
import { matchDataAssign } from '@/util/util';
import { getDateformat } from '@/util/getDate';
import MapData from '../../views/store/components/Map';
import config from '@/util/config';
export default {
    components: {
        Paging,
        MapData,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                property_name: '',
                community_name: '',
                province_id: '',
                city_id: '',
                area_id: [],
                start_time: '',
                end_time: '',
            },
            propertyList: [],
            showAdd: !1,
            type: 0,
            id: '',
            addForm: {
                property_id: '',
                community_name: '',
                province_id: '',
                city_id: '',
                area_id: '',
                longitude: '',
                latitude: '',
                address: '',
            },
            province_name: '',
            city_name: '',
            area_name: '',
            // 省级数据
            area: [],
            // 市级数据
            cityArr: [],
            // 区级数据
            areaArr: [],
            searchAddress: '',
            flag: false,
            mapFlag: false,
            showImport: !1,
            formData: '',
            filePath: '',
            templatePath: ''
        };
    },
    created () {
        this.getList();
        this.getArea();
        this.getProperty();
        this.getTemplatePath()
    },
    methods: {
        // 开启地图选择定位
        position () {
            if (!this.addForm.province_id || !this.addForm.city_id || !this.addForm.area_id) {
                this.$message({
                    message: '请选择完整地理区域',
                    type: 'warning ',
                });
                return;
            }
            this.searchAddress =
                this.area.find(item => item.id == this.addForm.province_id).name +
                this.cityArr.find(item => item.id == this.addForm.city_id).name +
                this.areaArr.find(item => item.id == this.addForm.area_id).name +
                this.addForm.address;
            this.mapFlag = true;
            this.$nextTick(() => {
                this.$refs.map.showDo();
            });
        },
        getValue (item) {
            this.searchAddress = item.value;
        },
        // 获取省市区信息
        getArea () {
            let that = this;
            this.$axios
                .get(this.$api.shopDiy.areaList, { tree: 1, level: 2 })
                .then(res => {
                    if (res.code == 0) {
                        this.area = res.result.list;
                        this.flag = true;
                    }
                });
        },
        getProperty () {
            this.$axios.post(this.$api.repair.property.lists).then(res => {
                if (res.code === 0) {
                    this.propertyList = res.result;
                }
            });
        },
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                property_name: '',
                community_name: '',
                province_id: '',
                city_id: '',
                area_id: [],
                start_time: '',
                end_time: '',
            };
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        dealSearchData () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.area_id.length) {
                searchForm.province_id = searchForm.area_id[0];
                searchForm.city_id = searchForm.area_id[1];
                searchForm.area_id = searchForm.area_id[2];
            }
            return searchForm;
        },
        getList () {
            let searchForm = this.dealSearchData();
            if (searchForm.start_time) {
                searchForm.end_time = searchForm.start_time[1] / 1000;
                searchForm.start_time = searchForm.start_time[0] / 1000;
            }
            this.$axios.post(this.$api.repair.community.list, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 三级联动选择地区
        sonArea (status, val) {
            if (status == 0) {
                let index = this.area.find(item => item.name == val);
                this.addForm.province_id = index.id;
                this.addForm.city_id = null;
                this.cityArr = index._child;
                this.city_name = null;
                this.addForm.area_id = null;
                this.areaArr = [];
                this.area_name = null;
                this.addForm.address = '';
                this.addForm.longitude = '';
                this.addForm.latitude = '';
            } else if (status == 1) {
                let index = this.cityArr.find(item => item.name == val);
                this.addForm.city_id = index.id;
                this.city_name = index.name;
                this.addForm.area_id = null;
                this.areaArr = index._child;
                this.area_name = null;
                this.addForm.address = '';
                this.addForm.longitude = '';
                this.addForm.latitude = '';
            } else if (status == 2) {
                let index = this.areaArr.find(item => item.name == val);
                this.addForm.area_id = index.id;
                this.addForm.address = '';
                this.addForm.longitude = '';
                this.addForm.latitude = '';
            }
        },
        // 获取选中的经纬度
        getAddress () {
            let obj = this.$refs.map.currentCoordinate;
            let item = this.$refs.map.address;
            this.addForm.latitude = obj.lat;
            this.addForm.longitude = obj.lng;
            this.addForm.address = item.street + item.streetNumber + this.searchAddress;
            this.mapFlag = false;
        },
        add () {
            this.type = 0;
            this.showAdd = !0;
            this.addForm = {
                property_id: '',
                community_name: '',
                province_id: '',
                city_id: '',
                area_id: '',
                address: '',
                longitude: '',
                latitude: '',
            };
            this.province_name = '';
            this.cityArr = [];
            this.city_name = '';
            this.areaArr = [];
            this.area_name = '';
        },
        // 调用接口时检测数据是否合法
        checkDataLegitimacy () {
            if (!this.addForm.property_id) {
                this.$message.warning('请选择物业');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.community_name) {
                this.$message.warning('请填写小区名称');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.province_id || !this.addForm.city_id || !this.addForm.area_id) {
                this.$message.warning('请选择小区区域');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.longitude || !this.addForm.latitude) {
                this.$message.warning('请选择经纬度');
                this.activeTabs = 'one';
                return false;
            }
        },
        addSubmit () {
            let checkRes = this.checkDataLegitimacy();
            if (checkRes === false) return;
            let url = this.type ? this.$api.repair.community.edit : this.$api.repair.community.add;
            let data = {
                property_id: this.addForm.property_id,
                community_name: this.addForm.community_name,
                province_id: this.addForm.province_id,
                city_id: this.addForm.city_id,
                area_id: this.addForm.area_id,
                address: this.addForm.address,
                longitude: this.addForm.longitude,
                latitude: this.addForm.latitude,
            };
            if (this.type) data.id = this.id;
            this.$axios.post(url, data).then(res => {
                if (res.code == 0) {
                    this.showAdd = !1;
                    this.getList();
                    this.$message({
                        message: `${this.type ? '编辑成功' : '添加成功'}`,
                        type: 'success',
                    });
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        edit (row) {
            this.type = 1;
            this.showAdd = !0;
            this.addFrom = matchDataAssign(this.addForm, row);
            this.id = row.id;
            this.province_name = this.area.find(item => item.id == this.addFrom.province_id).name;
            this.cityArr = this.area.find(item => item.id == this.addFrom.province_id)._child;
            this.city_name = this.cityArr.find(item => item.id == this.addFrom.city_id).name;
            this.areaArr = this.cityArr.find(item => item.id == this.addFrom.city_id)._child;
            this.area_name = this.areaArr.find(item => item.id == this.addFrom.area_id).name;
        },
        remove (id, index) {
            this.$confirm('确认删除此小区？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.repair.community.del, {
                        id: id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                            });
                            this.list.splice(index, 1);
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        getTemplatePath () {
            this.$axios.post(this.$api.repair.community.getTemplate)
                .then(res => {
                    if (res.code == 0) {
                        this.templatePath = config.baseurl + '/' + res.result
                    }
                });
        },
        getFile () {
            let files = this.$refs.inputer.files[0];
            let formData = new FormData();
            formData.append('file', files);
            this.filePath = files.name;
            this.formData = formData;
        },
        confirmImport () {
            if (!this.formData) return this.$message.warning('请先上传文件');
            this.$axios.uploadFile(this.$api.repair.community.import, this.formData).then(res => {
                if (res.code == 0) {
                    this.showImport = !1;
                    this.$message.success('小区导入成功');
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    },
};
</script>

<style lang="less" scoped>
.selectBox {
    display: flex;

    .el-select {
        flex: 1;

        /deep/ .el-input {
            width: 100% !important;
        }
    }
}

.el-main {
    background: #fff;
}

.el-form-search {
    margin-bottom: 25px;
}

.upload {
    margin-top: 20px;

    .el-button {
        position: relative;

        input {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
        }
    }
}
</style>
